@import 'tailwindcss/base';
@import 'tailwindcss/components';
@import 'tailwindcss/utilities';

body {
  font-family: Arial, sans-serif;
  background-color: #ccc;
}

ul {
  list-style-type: none;
}
